















































































































































































































.img-box {
  width: 150px;
  height: 100px;
  background-color: whitesmoke;
  border: solid 1px;
  text-align: center;
}
