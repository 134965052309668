























.sk-spinner {
  width: 20px;
  height: 20px;
}
