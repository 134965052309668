/* Generated by Glyphter (http://www.glyphter.com) on  Fri Oct 06 2017*/
@font-face {
    font-family: 'provider';
    src: url('./fonts/provider.eot');
    src: url('./fonts/provider.eot?#iefix') format('embedded-opentype'),
         url('./fonts/provider.woff') format('woff'),
         url('./fonts/provider.ttf') format('truetype'),
         url('./fonts/provider.svg#provider') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'provider';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-system_alliance:before{content:'\0041';}
.icon-CargoLine:before{content:'\0043';}
.icon-SAE:before{content:'\0045';}