@import "colors";
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "theme/style";
// BOOTSTRAP
//$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

.ibox-content {
  --test: "theme";
}

// Provider Font
@import "./provider/providers.css";

@import "./font.scss";

// Animate.css
@import "./theme/animate.css";

// FONT AWESOME
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome";

// FONT AWESOME BOOTSTRAP CHECKBOX
@import "~awesome-bootstrap-checkbox/awesome-bootstrap-checkbox";

// SUMMERNOTE
@import "~summernote/dist/summernote.css";

// MetisMenu
@import "~metismenu/dist/metisMenu.css";

// SWEETALERT2
@import "~sweetalert2/dist/sweetalert2.css";

// Toaster
@import "~toastr/build/toastr.css";

// jasny-bootstrap
@import "~jasny-bootstrap/dist/css/jasny-bootstrap.css";

@import "./text-spinners";

@import "./logos";
