@import "variables";
@import "profile";
@import "search";

@import "./wizard/jquery.steps.css";
@import "./icheck/icheck";
@import "./toastr/toastr.scss";

@import "~leaflet/dist/leaflet.css";
@import "~leaflet.awesome-markers/dist/leaflet.awesome-markers.css";

@import "./scrollbar";

// @import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";

@media only screen and (max-width: 480px) {
    .hide-small {
        display: none !important;
    }
}

@media only screen and (max-width: 820px ) {
    .admin-nav {
        display: none !important;
    }
}


@media only screen and (max-width: 1400px) {
    .image-promo {
        width: 600px;
    }
}

@media only screen and (max-width: 1100px) {
    .image-promo {
        display: none !important;
    }
}

/* OCORA styles*/
.alt-color { color: gainsboro; }

.bg-muted { background-color: dimgray; }

.dimgray-bg { background-color: dimgray; }

.ibox-content-faded {
    background: black;
    background: rgba(0, 0, 0, 0.6);
    border: 0
}

.menu-hidden {
    display: none !important;
}

.required:after {
    content: " *";
    font-weight: bold;
}

.min-faded { opacity: 0.8; }

.login-page, .consent-page { background-image: url("./backgrounds/city1.jpeg"); }

.forgot-pw-page { background-image: url("./backgrounds/password1.jpeg"); }

.register-page { background-image: url("./backgrounds/register1.jpeg"); }

body.single-page {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-blend-mode: inherit;
}

.panel { color: #676a6c; }


.single-page a { color: ghostwhite; }

.single-page a:hover { color: #d3d3d3; }

/* ocora colors*/


.ocora-green { color: $ocora-green }

.ocora-dark-green { color: $ocora-dark-green }

.ocora-turquoise { color: $ocora-turquoise }

.ocora-blue { color: $ocora-blue }

.ocora-red { color: $ocora-red }

[v-cloak] {
    display: none;
}

.note-editor { border: 1px solid rgb(169, 169, 169); }


.wizard .content {
    min-height: 100px;
}

.wizard .content > .body {
    width: 100%;
    height: auto;
    padding: 15px;
    position: relative;
}

.middle-box {
    max-width: 300px;
    margin: 0 auto;
    padding-top: 40px;
}

.lockscreen.middle-box {
    width: 200px;
    padding-top: 110px;
}

.login-page .middle-box {
    max-width: 600px;


    .text-danger{
        text-align: left;
    }
}


.loginColumns {
    max-width: 500px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
}

.passwordBox {
    max-width: 460px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
}

.logo-name {
    color: #e6e6e6;
    font-size: 180px;
    font-weight: 800;
    letter-spacing: -10px;
    margin-bottom: 0;
}

.middle-box h1 {
    font-size: 170px;
}

.wrapper .middle-box {
    margin-top: 140px;
}

.lock-word {
    z-index: 10;
    position: absolute;
    top: 110px;
    left: 50%;
    margin-left: -470px;
}

.lock-word span {
    font-size: 100px;
    font-weight: 600;
    color: #e9e9e9;
    display: inline-block;
}

.lock-word .first-word {
    margin-right: 160px;
}

.swal2-popup {
    font-size: 1.6rem !important;
}

.txt-bold {
    font-weight: bold;
}

.lazur-bg, .bg-info {
    background-color: $ocora-dark-green;
    color: #ffffff;
}

.image-promo {

    display: block;
    float: right;
    max-width: 900px;
    margin-bottom: -19px;
}

.header-content {
    float: left;
    display: inline-block;
}


.flag-inactive {
    opacity: 0.5;
}

.inactive > a {
    color: #536c83 !important;
}

.h-100 {
    height: 100%;
}


.ibox-content {
    --test: "app";
    --test1: "app";
  }
